<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="生成商名称">
				  <c-input name="manufacture_name"></c-input>
				</m-search-item>
				<m-search-item label="联系人">
				  <c-input name="manufacture_contacts"></c-input>
				</m-search-item>
				<m-search-item label="联系电话">
				  <c-input name="manufacture_phone"></c-input>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button color="sys" @click="createManufacture">新建生成商</c-button>
			</m-operate>

			<c-table ref="table" height="grow">
				<c-table-column
					label="生成商名称"
					width="150"
					name="manufacture_name"
				></c-table-column>

				<c-table-column
					label="联系人"
					width="120"
					name="manufacture_contacts"
				></c-table-column>

				<c-table-column
					label="联系电话"
					width="130"
					name="manufacture_phone"
				></c-table-column>

				<c-table-column
					label="创建时间"
					width="140"
					name="create_date"
				></c-table-column>

				<c-table-column
					type="button"
					label="操作"
					width="120"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b_iconsigned_mfg_edit')" @click="editManufacture(data)">编辑</c-table-button>
						<c-table-button v-if="auth('b_iconsigned_mfg_edit')" @click="deleteManufacture(data)">删除</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>

		<c-dialog ref="manufactureEdit" :title="editState ? '编辑生成商' : '新建生成商'" width="600" @resolve="submitManufacture">
			<c-form ref="manufactureForm" unit-width="100%">
				<c-form-item label="生成商名称" required>
					<c-input name="manufacture_name" required maxlength="30"></c-input>
				</c-form-item>
				<c-form-item label="联系人" required>
					<c-input name="manufacture_contacts" required maxlength="15"></c-input>
				</c-form-item>
				<c-form-item label="联系电话" required>
					<c-input name="manufacture_phone" required maxlength="11"></c-input>
				</c-form-item>
				<c-input type="hidden" name="id"></c-input>
			</c-form>
		</c-dialog>
	</page>
</template>

<script>
import {cryptoJs} from '@deanwu/vue-component-library'
import {mapState} from 'vuex'

export default {
	name: 'm_employee',
	components: {
		
	},
	data() {
		return {
		  teamOptions:[],
		  editState: false
		}
	},
	computed: {
		
	},
	mounted() {
        this.$refs.search.search();
	},
	methods: {
		tabHandle(item) {
			this.$refs.search.search();
		},
		searchHandle(data) {
			this.$refs.table.load({
				url: '/manufacture/list',
				data
			});
		},
		//创建账号
		createManufacture() {
			this.editState = false;
			this.$refs.manufactureForm.clear();
			this.$refs.manufactureForm.set({});
			this.$refs.manufactureEdit.open();
		},
		//编辑账号
		editManufacture(val) {
			this.editState = true;
			this.$refs.manufactureForm.set(val);
			this.$refs.manufactureEdit.open();
		},
		//删除账号
		deleteManufacture(val){
			this.$confirm({
				title: '确认',
				message: '确定要删除【'+val.manufacture_name+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/manufacture/delete',
						data: {id:val.id},
						loading: true,
						success: data => {
							this.$message({
							  message: '删除成功',
							  type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//提交保存
		submitManufacture(stop) {
			stop();
			this.$refs.manufactureForm.submit({
				url: this.editState ? '/manufacture/edit' : '/manufacture/add',
				rule: {
					manufacture_name: '请填生成商名称',
					manufacture_contacts: '请填写生成商联系人',
					manufacture_phone: '请填写生成商联系电话'
				},
				dataFormatter: data => {
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.$refs.table.update();
					this.$refs.manufactureEdit.close();
				}
			});
		},
	}
}
</script>